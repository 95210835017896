import Button from "@/components/button";
import Input from "@/components/input";
import Dropdown from "@/components/dropdown";
import VueMultiselect from "@/components/vue-multiselect";
import Loader from "@/components/loader";
import "@/assets/css/multiselect.scss";
import {mapGetters, mapMutations, mapActions} from "vuex";
import {FEATURES} from "@/utils/constants";
import axios from "@/axios";
import _ from "lodash";
import titleMixin from "@/mixins/titleMixin";
import productSidepanel from "@/views/product-sidepanel";

// import keywordsProduct from "@/components/keywords-product";
const keywordsProduct = () => import("@/views/product-configure/tools/keywords-product");
const researchMethodology = () => import("@/views/company-methodology");
const sources = () => import("@/views/sources");
const searchEngineQueries = () => import("@/views/product-configure/tools/search-engine-queries");
const reportTemplates = () => import("@/views/product-configure/tools/report-templates");
const outputTemplate = () => import("@/views/product-configure/tools/output-template");
const productToolsPanel = () => import("@/views/research/tools/ToolsTreeView.vue");

const ErrorMessage = () => import("@/components/server-error-message");

export default {
    name: "product-configure",
    components: {
        "neo-button": Button,
        "neo-input": Input,
        "neo-dropdown": Dropdown,
        VueMultiselect,
        "product-sidepanel": productSidepanel,
        "product-tools": productToolsPanel,
        "neo-loader": Loader,
        //   keywordsProduct
        ErrorMessage,
    },
    props: [],
    mixins: [titleMixin],
    title() {
        return `Product Admin`;
    },
    data() {
        return {
            searchTool: "",
            products: [],
            tools: [],
            monitoringTools: [],
            monitoringFilter: false,
            collapsed: false,
            collapseIconVisible: false,
            activeMonitoring: null,
            currentSelected: "Keywords",
            isProductLoading: false, // TODO use when product is loading
            monitoringexits: "",
        };
    },

    computed: {
        ...mapGetters(["getProduct", "getReadOnlyMode", "getResearchData", "getAnalysisToolData", "getAllToolNames", "getPermissions", "checkPermissionsFetched", "getCompanyProducts"]),
        isProductAuth() {
            return true;
        },

        productId() {
            return this.getProduct?.key;
        },

        toolsList() {
            let nonMonitoringTools = ["name-search-tool", "email-search-tool"];
            let data = this.getAllToolNames ? _.cloneDeepWith(this.getAllToolNames) : {};
            // Filter tools based on permissions
            data.children = data?.children?.filter((ele) => this.getPermissions.find((ele1) => FEATURES["monitoring"][ele1] == ele.activeProcess) && !nonMonitoringTools.includes(ele.activeProcess));

            // map tools with monitoring tools data
            data.children = data?.children?.map((ele) => {
                let match = this.monitoringTools?.find((tool) => tool.tool === ele.activeProcess);
                return {
                    ...ele,
                    ...match,
                };
            });

            // Filter tools with monitoring on
            data = data?.children?.filter((ele) => {
                if (this.monitoringFilter) return this.tools.includes(ele.text);
                else return true;
            });
            // Filter tools with monitoring enabled
            // data = data?.filter((ele) => ele.activeProcess !== 'url');
            // Search Tool based on search filter
            return {children: data?.filter((tool) => tool.text?.toLowerCase().includes(this.searchTool?.toLowerCase()))};
        },
        socialTools() {
            const tools = this.toolsList?.children.filter((tool) => tool.heading === "Social Media");
            this.monitoringexits = this.getPermissions.includes("monitoring__monitoring");
            return {children: tools};
        },

        nonSocialTools() {
            const tools = this.toolsList?.children.filter((tool) => tool.heading !== "Social Media");
            return {children: tools};
        },
        selectConfig() {
            return {
                Keywords: {
                    component: keywordsProduct,
                },
                Monitoring: {},
                Sources: {
                    component: sources,
                },
                Methodology: {
                    component: researchMethodology,
                },
                "Output Templates": {},
                "Report Templates": {
                    component: reportTemplates,
                },
                "Search Engine Queries": {
                    component: searchEngineQueries,
                },
                "Output Template": {
                    component: outputTemplate,
                },
            };
        },
    },
    async mounted() {
        await this.fetchAnalysisTools(); // Function to fetch list of tools
        await this.fetchPermissions(); // Function to fetch permissions
        await this.fetchCompanyProducts(); // Function to fetch company related products
        this.products = [
            {
                group: "Products",
                values: this.getCompanyProducts,
            },
        ];
        if (this.$route.query.product && !this.getProduct?.key) {
            const product = this.getCompanyProducts.find((prod) => prod.name === this.$route.query.product);
            this.$store.dispatch("setProduct", product);
        }
        await this.getDataMonitoring(); // Function to fetch monitoring data
    },
    methods: {
        ...mapActions(["fetchAnalysisTools", "fetchPermissions", "fetchCompanyProducts"]),

        // function to change selected tool
        changeSelected(tool) {
            this.currentSelected = tool;
        },

        // function to update monitoring settings
        updateMonitoring(data) {
            this.activeMonitoring = {
                ...this.activeMonitoring,
                ...{
                    status: data.monitoring_status,
                    frequency: data.frequency,
                },
            };
            this.updateActiveMonitoring();
        },

        updateFrequency(data) {
            this.activeMonitoring = data?.query;
        },

        // Function to get monitoring data
        async getDataMonitoring() {
            let url = `/data-monitoring/product?product_id=${this.getProduct?.key}`;
            // let data = {
            //     product_name: this.selectedProduct?.name,
            //     product_id: this.selectedProduct?.key,
            // };
            try {
                // let response = await axios.post(url, data);
                let response = await axios.post(url);
                if (response?.status === 200 && response?.data?.data) {
                    this.monitoringTools = response.data.data.map((tool) => ({
                        ...tool,
                    }));
                    this.tools = this.monitoringTools?.map((tool) => tool.tool) || [];
                }
            } catch (error) {
                console.error(error);
            }
        },

        updateActiveMonitoring() {
            if (this.tools.includes(this.activeMonitoring.tool)) this.updateDataMonitoring(this.activeMonitoring);
            else this.createDataMonitoring(this.activeMonitoring);
        },

        // Function to set new monitoring
        async createDataMonitoring(monitoring) {
            let url = `/data-monitoring`;
            let freq = parseInt(monitoring.frequency, 10);
            let data = {
                frequency: freq || freq === 0 ? freq : 365,
                tool: monitoring.activeProcess,
                monitoring_type: "product",
                product_name: this.getProduct?.name,
                product_id: this.getProduct?.key,
                status: monitoring.status,
            };
            try {
                let response = await axios.post(url, data);
                if ((response?.status === 201 || response?.status === 200) && response?.data.status) {
                    this.$toast.success("Monitoring set successfully !");
                    await this.getDataMonitoring(); // Function to fetch monitoring data
                }
            } catch (error) {
                console.error(error);
            }
        },

        // Function to update monitoring data
        async updateDataMonitoring(monitoring) {
            let url = `/data-monitoring`;
            let data = {
                id: monitoring._id,
                frequency: parseInt(monitoring.frequency, 10),
                monitoring_type: "product",
                status: monitoring.status,
            };
            try {
                let response = await axios.put(url, data);
                if (response?.status === 200 && response?.data.status) {
                    this.$toast.success("Monitoring updated successfully !");
                    await this.getDataMonitoring(); // Function to fetch monitoring data
                    // this.close();
                }
            } catch (error) {
                console.error(error);
            }
        },

        showCollapseIcon() {
            this.collapseIconVisible = true;
        },

        hideCollapseIcon() {
            this.collapseIconVisible = false;
        },
    },
};
