import Button from "@/components/button";
import Input from "@/components/input";
import Treeview from "@/components/treeview";
import {mapGetters} from "vuex";
export default {
    name: "product-sidepanel",
    components: {
        "neo-button": Button,
        "neo-input": Input,
        treeview: Treeview,
    },
    props: [],
    data() {
        return {
            // TODO get list from props to reuse component in other sections
            productSettings: [
                {
                    title: "Keywords",
                    icon: "/icons/keywords.png",
                    enabled: true,
                },
                {
                    title: "Monitoring",
                    icon: "/icons/frequency.png",
                    monitoring: true,
                    enabled: false,
                },
                {
                    title: "Sources",
                    icon: "/icons/sources.png",
                    enabled: false,
                },
                {
                    title: "Methodology",
                    icon: "/icons/methodology.png",
                    enabled: false,
                },
                {
                    //     title: 'Output Templates',
                    //     icon: '/icons/output_templates.png',
                    //     enabled: false,
                    // }, {
                    title: "Report Templates",
                    icon: "/icons/report_templates.png",
                    enabled: true,
                },
                {
                    title: "Search Engine Queries",
                    icon: "/icons/search_engine_queries.png",
                    enabled: true,
                },
                {
                    // connected with report builder
                    title: "Output Template",
                    icon: "/icons/output_templates.png",
                    enabled: true,
                },
            ],
            selectedTab: "Keywords",
        };
    },

    computed: {
        ...mapGetters(["getPermissions"]),
        isMonitoringEnabled() {
            // this.productSettings.monitoring = this.getPermissions.includes("monitoring_monitoring")
            // return process.env.VUE_APP_TAG_NAME === "local" || process.env.VUE_APP_TAG_NAME === "development" || process.env.VUE_APP_TAG_NAME === "staging";
            return this.getPermissions.includes("monitoring__monitoring");
        },
    },
    async mounted() {},
    methods: {
        handleSidepanelClick(event) {
            if (event.enabled || (event.monitoring && this.isMonitoringEnabled)) {
                this.$emit("selectedChanged", event.title);
                this.selectedTab = event.title;
            }
        },
    },
};
